let stack = "HTML, CSS, JavaScript, React.js, React Native, Node, Express, MongoDB, Git, basics of Linux, deployment"

let calendar = [
{filter: 'JSB',
startMonth: 'June',
startDate: '24',
endMonth: 'September',
endDate: '06',
title: 'JavaScript Full-Stack Bootcamp',
url: '/barcelona-code-school/javascript-full-stack-bootcamp/',
desc: 'Become a developer in 9 weeks',
notes: '&#x2600; Includes a Summer break between July&nbsp29 – August&nbsp;11',
fullDesc: "9-week course, <span className='highlighter'>in-person</span>, in English, full-time.",
cost: 'Registration prepayment 580€.',
checkout: "<Checkout name={'JavaScript Full-Stack Bootcamp'} description={'JS Bootcamp, June 24'} amount={580} label = 'Sign up' />",
finePrint: `By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.`},
{filter: 'JSB',
startMonth: 'July',
startDate: '24',
endMonth: 'September',
endDate: '06',
title: 'JavaScript Full-Stack Bootcamp',
url: '/barcelona-code-school/javascript-full-stack-bootcamp/',
desc: 'Become a developer in 9 weeks',
notes: '&#x2600; Includes a Summer break between July&nbsp29 – August&nbsp;11',
fullDesc: "9-week course, <span className='highlighter'>in-person</span>, in English, full-time.",
cost: 'Registration prepayment 580€.',
checkout: "<Checkout name={'JavaScript Full-Stack Bootcamp'} description={'JS Bootcamp, June 24'} amount={580} label = 'Sign up' />",
finePrint: `By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.`}
]

export  {stack, calendar}